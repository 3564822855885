const blockPhotoGallery = async($block) => {
	const { Swiper } = await import( 'swiper' );
	const { Autoplay, Navigation } = await import( 'swiper/modules' );
	await import( 'swiper/css/bundle' );

	const $slider = $block.find('.slider-photo-gallery');
	if (!$slider.length) {return false;}

	const photoGallerySwiper = new Swiper($slider[0], {
		modules: [Autoplay, Navigation],
		init: false,
		loop: true,
		centeredSlides: true,
		simulateTouch: true,
		slidesPerView: 1,
		speed: 4000,
		autoplay: {
			delay: 0,
			disableOnInteraction: false,
		},
		breakpoints: {
			1024: {
				simulateTouch: false,
				slidesPerView: 3,
				autoplay: {
					delay: 0,
					disableOnInteraction: false,
				},
				speed: 5000,
			}
		}
	});

	photoGallerySwiper.init();
};

themeUtils.loadBlock( blockPhotoGallery, 'photo-gallery', '.section-photo-gallery' );
